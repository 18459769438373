@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #minorista,
  #contact,
  #footer, #header, #socios-gastronomicos, #catalogo, #curriculum, footer {
    width: 111%;
  }


}
