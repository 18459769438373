.footer{
    height: 40px;
    background: var(--light-brown);
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.parrafo {
    color: var(--orange);
    font-size: 16px;
}