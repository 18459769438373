span {
    color: var(--orange);
}


.subtitleContact {
    text-transform: uppercase;
    letter-spacing: 8px;
    color: var(--orange);
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.horarios{
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 0;
    padding: 0;
}

.infoItem {
    margin-bottom: 25px;
    height: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textoBloque{
    font-size: 18px;
    color: white;
}

.textoBloqueSab {
    font-size: 18px;
    color: white;
    margin-left: 40px;
}

.textoBloqueDom {
    font-size: 18px;
    color: white;
    padding: 10px;
    border: 1px solid var(--orange);
}

.horariosContainer{
    margin-bottom: 40px;
}

.locationContainer{
    margin-top: 150px;
    margin-bottom: 100px;
}

.mapa{
    width: 52%;
    height: 100%;
    border-radius: 10px;
    border: none;
}

.mapaContainer {
    display: flex;
    width: 100%;
    height: 55%;
    justify-content: space-around;
    align-items: flex-start;
}

.contact_box_container {
    display: flex;
    justify-content: center;
}

.contact_box {
    display: flex;
    flex-direction: column;
    justify-content: center; 
}
.lineaContainer{
    margin-bottom: 30px;
    width: 68%;
    background-color: var(--orange);
}

/*---------  RESPONSIVE ---------*/
/* mobile version */
@media screen and (max-width: 767px) {
    .mapa{
        width: 80%;
    }
    .subtitleContact {
        line-height: 30px;
    }
}

@media screen and (max-width: 480px) {
    .textoBloque{
        font-size: 16px;
    }
    .mapa{
        margin-top: 20px;
        margin-bottom: 30px;
        width: 75%;
        height: 100%;
    }
}
